<template>
  <div class="form-note">
    <slot />
  </div>
</template>

<script setup>

</script>

<style scoped lang="scss">
.form-note {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-elements-secondary);
  text-align: center;
  :deep(a) {
    color: var(--color-primary);
  }
}
</style>
